@import url(https://fonts.googleapis.com/css2?family=Cabin:wght@700&family=Lato&family=Abel&display=swap);
:root {
  --main_color: #cb3efd;
  --text: #424242;
  --links: #0aca93;
  --menu_bg: #424242;
  --menu_links: #0aca93;
  --menu_badge_bg: #0aca93;
  --menu_badge_txt: #424242;

  --roxo: #a9039b;
  /*--roxin: #ffc9fa;
  --verde: #6afaa9;*/
  --roxin: #ffacf7;
  --verde: #45d685;
  --verdin: #cff4df;
  --cinzao: #424242;
  --cinza: #777777;
  --cinzin: #bbbbbb;

  --image_mask_start: 247,200,250;
  --image_mask_end: 169, 3, 155;
  --image_mask_start: 207, 244, 224;
  --image_mask_end: 106, 250, 169;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Cabin', sans-serif;
  text-transform: uppercase;
  color:#a9039b;
  color:var(--roxo);
  text-shadow: 2px 2px 0px #ffacf7;
  text-shadow: 2px 2px 0px var(--roxin);
}
h3, h4, h5, h6 {text-shadow: 1px 1px 0px #ffacf7;text-shadow: 1px 1px 0px var(--roxin);}
h3 {color:#45d685;color:var(--verde)}

ul {list-style: none; padding:0;}
header {
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction:column;
          flex-direction:column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  position:relative;
  z-index:0;
}
header:before {
  content: "";
  z-index: -1;
  width: 80%;
  height:90%;
  position:absolute;
  background-image: linear-gradient(to bottom right, #a9039b 50%, transparent 50%);
  left: 0;
  top: 0;
}
header:after {
  content: "";
  z-index: -1;
  width: 45%;
  height:130%;
  position:absolute;
  right:0;
  top: 0;
  background-image:
    linear-gradient(#6afaa9 2px, transparent 2px),
    linear-gradient(90deg, #6afaa9 2px, transparent 2px);
  background-size: 20px 20px, 20px 20px;
  background-position: -1px -2px, -1px -2px;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
          clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
}
#socials {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  font-size:14px;
  letter-spacing:1px;
  line-height:20px;
  vertical-align: middle;
}
#socials a {
  color: #ffc9fa;
  display: inline-block;
  transition: all .8s ease;
}
#socials a .icon {
    display: block;
    margin-right: 10px;
    height: 25px;
}
#socials a .icon svg {fill:#ffc9fa; transition: all .8s ease;}
#socials a span {display:none;}
#socials a:hover, #socials a:focus, #socials a:active {color: #6afaa9;}
#socials a:hover .icon svg, #socials a:focus .icon svg, #socials a:active .icon svg {fill: #6afaa9;}
header h1 {
  margin-top: 30px;
  margin-bottom: 40px;
  font-size:40px;
  letter-spacing:-1px;
  line-height: 40px;
  font-weight:bold;
  text-align:right;
  cursor: default;
}
header h1 span {transition: all .6s ease;
  text-shadow: 2px 2px 0px #ffacf7;
  text-shadow: 2px 2px 0px var(--roxin);}
header h1 span:hover {color: #6afaa9;}
header h1 small {
  font-size: .5em;
  line-height: 1;
  margin-top: -6px;
  display: block;
  color: #ffacf7;
  color: var(--roxin);
  text-shadow: 1px 1px 0px #45d685;
  text-shadow: 1px 1px 0px var(--verde);
}
header picture {
  position:absolute;
  left: 10px;
  top: 60px;
  border-radius:50%;
  border: 3px solid #6afaa9;
  overflow:hidden;
  width: 80px;
  height: 80px;
}
header picture:before {content: "A"; position:absolute; width:100%; height:100%; display: grid;
    place-items: center; font-size:60px; line-height:60px; color: #ffc9fa; font-family: 'Cabin', sans-serif; background:#6afaa9;}
header picture img {-webkit-filter: grayscale(1);filter: grayscale(1); transition: all .8s ease;}
header picture:hover img {-webkit-filter: grayscale(0);filter: grayscale(0); }



@media (min-width:470px) {
  header picture {width: 150px; height: 150px; }
  header h1 {font-size:50px; line-height: 50px; }
}
@media (min-width:800px) {
  header {padding:30px; -webkit-flex-direction:row; flex-direction:row;     -webkit-justify-content: space-between;     justify-content: space-between;}
  header:before {width: 70%; height:100%;}
  header h1 {font-size:60px; line-height: 60px; margin:0;}
  header picture {position:relative; left: unset; width: 200px; height: 200px; }
  #socials a {display: -webkit-flex;display: flex; vertical-align: middle; -webkit-justify-content: flex-start; justify-content: flex-start; -webkit-align-items: center; align-items: center; margin-bottom:6px;}
  #socials a span {display:inline;display:initial; line-height:25px;}
}

@media (max-height:550px) {
  header:before {height: 80%;}
}
@media (max-width:325px) {
  header:before {height: 55%;}
  header picture {width: 120px; height: 120px; left: 50%; margin-left: -60px; top: 60px;}
  header h1 {margin-top: 160px; margin-bottom: 0; text-align: center; width: 100%;}
}
* {outline: 0; max-width:100%;}

html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  background: var(--verdin);
  font-size:18px;
}

.App {
  font-family: Lato, sans-serif;
  text-align: left;
  font-size:18px;
}

.custom-select {
  border-radius: 0;
  font-family: Lato, sans-serif;
}
.custom-select:focus {
    border-color:var(--verde);
    box-shadow: 0 0 0 0px var(--verde);
}
*:focus:not(:focus-visible), .custom-select:focus:not(:focus-visible) { outline: none; } { outline: none; }

footer {
  position:fixed;
  bottom:0;
  left:0;
  padding:30px;
  width:100%;
  z-index:0;
  pointer-events: none;
}
footer:before {
  content: "";
  z-index: -1;
  width: 35%;
  height:200%;
  position:absolute;
  left:0;
  bottom: 0;
  background-image:
    linear-gradient(#ffc9fa 2px, transparent 2px),
    linear-gradient(90deg, #ffc9fa 2px, transparent 2px);
  background-size: 20px 20px, 20px 20px;
  background-position: -1px -2px, -1px -2px;
  -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
          clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
}

footer:after {
  content: "";
  z-index: -1;
  width: 70%;
  height:100%;
  position:absolute;
  left:0;
  bottom: 0;
  background: var(--roxin);
  opacity: .7;
  -webkit-clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
          clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
}
footer nav {
    display: inline-block;
    pointer-events: all;
}
footer a {
  display:block;
  color: var(--roxo);
  font-weight: bold;
  font-style: italic;
  line-height: 25px;
  text-shadow: 0px 0px 5px #fff;
  transition: all .8s ease;
}
footer a[aria-current=page] {

}
footer a:before {
  content: "";
  display:inline-block;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 13px solid var(--roxo);
  -webkit-filter: drop-shadow(1px 1px 0px var(--verde));
          filter: drop-shadow(1px 1px 0px var(--verde));
  border-bottom: 5px solid transparent;
  margin-right: 5px;
  transition: color .8s ease;
}
footer a:hover {color: var(--roxo); }
footer a:focus, footer a:active {color: var(--verde);}
footer a:hover:before, footer a:focus:before, 
footer a:active:before, footer a[aria-current=page] {
  border-left-color: var(--verde); }
footer a:hover:before, footer a:focus:before, 
  footer a:active:before {
  -webkit-filter: drop-shadow(1px 1px 0px var(--roxo));
          filter: drop-shadow(1px 1px 0px var(--roxo));
}
#content {grid-area: "content";}
aside {font-size: 14px; grid-area: "sidebar"; -webkit-order: 2; order: 2; }
main {
  display: grid;
  grid-template-areas: 
    "content"
    "sidebar";
  grid-gap: 0px;
  margin-bottom: 80px;
}
section {padding:60px 30px;}

aside p {margin-bottom: 0;}
#mainSkills {display: grid; grid-template-columns: 1fr 1fr; grid-gap:5px 20px; list-style: none; padding: 0;}
progress[value] {
  -webkit-appearance: none;
          appearance: none;
  width: 100%; 
  height: 20px;
  border: 2px solid var(--verde);
  background-color: transparent;
  border-radius: 0px;
  color: var(--roxin);
  position: relative;      
}
progress[value]::-webkit-progress-bar {
    background-color: transparent;
    border-radius: 0px;
}
progress[value]::-webkit-progress-value {
    position: relative;    
    border-radius:0px;   
    background: var(--roxin);
    /*background-image: linear-gradient(-45deg, #a9039b, #ffc9fa, #a9039b, #ffc9fa);*/
    background-size: 400% 400%;
    -webkit-animation: progressFill 5s ease infinite;
            animation: progressFill 5s ease infinite;
    -webkit-transition: 0.5s all;
    transition: 0.5s all;
}
.progress-bar {
    background-color: transparent;
    border-radius: 0px;
    border: 2px solid var(--verde);
    width: 100%; 
    height:20px;
}
progress[value]:after {
  content: attr(data-years) " years";
  color: #5d5d5d;
  font-size: .6rem;
  text-align: center;
  width: 100%;
  top: 0;
  position: absolute;
}
.progress-bar span {
    background: var(--roxin);
    /*background-image: linear-gradient(-45deg, #a9039b, #ffc9fa, #a9039b, #ffc9fa);*/
    background-size: 400% 400%;
    -webkit-animation: progressFill 5s ease infinite;
            animation: progressFill 5s ease infinite;
    transition: 0.5s all;
    border-radius: 0px;
    display: block;
    text-indent: -9999px;
}
p[data-value] {position: relative; }

.page h2 {margin-bottom: 40px; padding-bottom:10px; border-bottom: 2px solid;}

#resume {text-align: center; display: block; border: 2px solid var(--roxin); color: var(--roxo); margin: 20px auto 0;
  padding:8px; font-weight: bold; font-style: italic;
}
#resume:hover {background: var(--roxin); text-decoration: none;}

@-webkit-keyframes progressFill {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
@keyframes progressFill {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}


@media (min-width:800px) {
  body {background-image: linear-gradient(90deg, #ffffff 30%, #cff4df 30%);}
  main {grid-template-columns: 28% 68%; grid-gap: 4%; margin-bottom:0;}
  footer:after {width: 40%; height:120%;}
  aside {padding-bottom: 120px; -webkit-order: 0; order: 0;}
}

@media (max-height:550px) {
  main {margin-bottom: 120px;}
  footer {padding:10px;}
  footer:before {height: 180%; width:20%;}
  footer:after {height: 120%; width:40%;}
}
@media (max-width:325px) {
  footer:before {width: 60%;}
  footer:after {width: 90%;}
}
