:root {
  --main_color: #cb3efd;
  --text: #424242;
  --links: #0aca93;
  --menu_bg: #424242;
  --menu_links: #0aca93;
  --menu_badge_bg: #0aca93;
  --menu_badge_txt: #424242;

  --roxo: #a9039b;
  /*--roxin: #ffc9fa;
  --verde: #6afaa9;*/
  --roxin: #ffacf7;
  --verde: #45d685;
  --verdin: #cff4df;
  --cinzao: #424242;
  --cinza: #777777;
  --cinzin: #bbbbbb;

  --image_mask_start: 247,200,250;
  --image_mask_end: 169, 3, 155;
  --image_mask_start: 207, 244, 224;
  --image_mask_end: 106, 250, 169;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Cabin', sans-serif;
  text-transform: uppercase;
  color:var(--roxo);
  text-shadow: 2px 2px 0px var(--roxin);
}
h3, h4, h5, h6 {text-shadow: 1px 1px 0px var(--roxin);}
h3 {color:var(--verde)}

ul {list-style: none; padding:0;}
header {
  padding: 10px;
  display: flex;
  flex-direction:column;
  justify-content: space-around;
  align-items: flex-end;
  position:relative;
  z-index:0;
}
header:before {
  content: "";
  z-index: -1;
  width: 80%;
  height:90%;
  position:absolute;
  background-image: linear-gradient(to bottom right, #a9039b 50%, transparent 50%);
  left: 0;
  top: 0;
}
header:after {
  content: "";
  z-index: -1;
  width: 45%;
  height:130%;
  position:absolute;
  right:0;
  top: 0;
  background-image:
    linear-gradient(#6afaa9 2px, transparent 2px),
    linear-gradient(90deg, #6afaa9 2px, transparent 2px);
  background-size: 20px 20px, 20px 20px;
  background-position: -1px -2px, -1px -2px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
}
#socials {
  align-self: flex-start;
  font-size:14px;
  letter-spacing:1px;
  line-height:20px;
  vertical-align: middle;
}
#socials a {
  color: #ffc9fa;
  display: inline-block;
  transition: all .8s ease;
}
#socials a .icon {
    display: block;
    margin-right: 10px;
    height: 25px;
}
#socials a .icon svg {fill:#ffc9fa; transition: all .8s ease;}
#socials a span {display:none;}
#socials a:hover, #socials a:focus, #socials a:active {color: #6afaa9;}
#socials a:hover .icon svg, #socials a:focus .icon svg, #socials a:active .icon svg {fill: #6afaa9;}
header h1 {
  margin-top: 30px;
  margin-bottom: 40px;
  font-size:40px;
  letter-spacing:-1px;
  line-height: 40px;
  font-weight:bold;
  text-align:right;
  cursor: default;
}
header h1 span {transition: all .6s ease;
  text-shadow: 2px 2px 0px var(--roxin);}
header h1 span:hover {color: #6afaa9;}
header h1 small {
  font-size: .5em;
  line-height: 1;
  margin-top: -6px;
  display: block;
  color: var(--roxin);
  text-shadow: 1px 1px 0px var(--verde);
}
header picture {
  position:absolute;
  left: 10px;
  top: 60px;
  border-radius:50%;
  border: 3px solid #6afaa9;
  overflow:hidden;
  width: 80px;
  height: 80px;
}
header picture:before {content: "A"; position:absolute; width:100%; height:100%; display: grid;
    place-items: center; font-size:60px; line-height:60px; color: #ffc9fa; font-family: 'Cabin', sans-serif; background:#6afaa9;}
header picture img {filter: grayscale(1); transition: all .8s ease;}
header picture:hover img {filter: grayscale(0); }



@media (min-width:470px) {
  header picture {width: 150px; height: 150px; }
  header h1 {font-size:50px; line-height: 50px; }
}
@media (min-width:800px) {
  header {padding:30px; flex-direction:row;     justify-content: space-between;}
  header:before {width: 70%; height:100%;}
  header h1 {font-size:60px; line-height: 60px; margin:0;}
  header picture {position:relative; left: unset; width: 200px; height: 200px; }
  #socials a {display: flex; vertical-align: middle; justify-content: flex-start; align-items: center; margin-bottom:6px;}
  #socials a span {display:initial; line-height:25px;}
}

@media (max-height:550px) {
  header:before {height: 80%;}
}
@media (max-width:325px) {
  header:before {height: 55%;}
  header picture {width: 120px; height: 120px; left: 50%; margin-left: -60px; top: 60px;}
  header h1 {margin-top: 160px; margin-bottom: 0; text-align: center; width: 100%;}
}