@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@700&family=Lato&family=Abel&display=swap');


* {outline: 0; max-width:100%;}

html,
body {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  background: var(--verdin);
  font-size:18px;
}

.App {
  font-family: Lato, sans-serif;
  text-align: left;
  font-size:18px;
}

.custom-select {
  border-radius: 0;
  font-family: Lato, sans-serif;
}
.custom-select:focus {
    border-color:var(--verde);
    box-shadow: 0 0 0 0px var(--verde);
}
*:focus:not(:focus-visible), .custom-select:focus:not(:focus-visible) { outline: none; } { outline: none; }

footer {
  position:fixed;
  bottom:0;
  left:0;
  padding:30px;
  width:100%;
  z-index:0;
  pointer-events: none;
}
footer:before {
  content: "";
  z-index: -1;
  width: 35%;
  height:200%;
  position:absolute;
  left:0;
  bottom: 0;
  background-image:
    linear-gradient(#ffc9fa 2px, transparent 2px),
    linear-gradient(90deg, #ffc9fa 2px, transparent 2px);
  background-size: 20px 20px, 20px 20px;
  background-position: -1px -2px, -1px -2px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
}

footer:after {
  content: "";
  z-index: -1;
  width: 70%;
  height:100%;
  position:absolute;
  left:0;
  bottom: 0;
  background: var(--roxin);
  opacity: .7;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
}
footer nav {
    display: inline-block;
    pointer-events: all;
}
footer a {
  display:block;
  color: var(--roxo);
  font-weight: bold;
  font-style: italic;
  line-height: 25px;
  text-shadow: 0px 0px 5px #fff;
  transition: all .8s ease;
}
footer a[aria-current=page] {

}
footer a:before {
  content: "";
  display:inline-block;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-left: 13px solid var(--roxo);
  filter: drop-shadow(1px 1px 0px var(--verde));
  border-bottom: 5px solid transparent;
  margin-right: 5px;
  transition: color .8s ease;
}
footer a:hover {color: var(--roxo); }
footer a:focus, footer a:active {color: var(--verde);}
footer a:hover:before, footer a:focus:before, 
footer a:active:before, footer a[aria-current=page] {
  border-left-color: var(--verde); }
footer a:hover:before, footer a:focus:before, 
  footer a:active:before {
  filter: drop-shadow(1px 1px 0px var(--roxo));
}
#content {grid-area: "content";}
aside {font-size: 14px; grid-area: "sidebar"; order: 2; }
main {
  display: grid;
  grid-template-areas: 
    "content"
    "sidebar";
  grid-gap: 0px;
  margin-bottom: 80px;
}
section {padding:60px 30px;}

aside p {margin-bottom: 0;}
#mainSkills {display: grid; grid-template-columns: 1fr 1fr; grid-gap:5px 20px; list-style: none; padding: 0;}
progress[value] {
  appearance: none;
  width: 100%; 
  height: 20px;
  border: 2px solid var(--verde);
  background-color: transparent;
  border-radius: 0px;
  color: var(--roxin);
  position: relative;      
}
progress[value]::-webkit-progress-bar {
    background-color: transparent;
    border-radius: 0px;
}
progress[value]::-webkit-progress-value {
    position: relative;    
    border-radius:0px;   
    background: var(--roxin);
    /*background-image: linear-gradient(-45deg, #a9039b, #ffc9fa, #a9039b, #ffc9fa);*/
    background-size: 400% 400%;
    animation: progressFill 5s ease infinite;
    transition: 0.5s all;
}
.progress-bar {
    background-color: transparent;
    border-radius: 0px;
    border: 2px solid var(--verde);
    width: 100%; 
    height:20px;
}
progress[value]:after {
  content: attr(data-years) " years";
  color: #5d5d5d;
  font-size: .6rem;
  text-align: center;
  width: 100%;
  top: 0;
  position: absolute;
}
.progress-bar span {
    background: var(--roxin);
    /*background-image: linear-gradient(-45deg, #a9039b, #ffc9fa, #a9039b, #ffc9fa);*/
    background-size: 400% 400%;
    animation: progressFill 5s ease infinite;
    transition: 0.5s all;
    border-radius: 0px;
    display: block;
    text-indent: -9999px;
}
p[data-value] {position: relative; }

.page h2 {margin-bottom: 40px; padding-bottom:10px; border-bottom: 2px solid;}

#resume {text-align: center; display: block; border: 2px solid var(--roxin); color: var(--roxo); margin: 20px auto 0;
  padding:8px; font-weight: bold; font-style: italic;
}
#resume:hover {background: var(--roxin); text-decoration: none;}

@-webkit-keyframes progressFill {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
@keyframes progressFill {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}


@media (min-width:800px) {
  body {background-image: linear-gradient(90deg, #ffffff 30%, #cff4df 30%);}
  main {grid-template-columns: 28% 68%; grid-gap: 4%; margin-bottom:0;}
  footer:after {width: 40%; height:120%;}
  aside {padding-bottom: 120px; order: 0;}
}

@media (max-height:550px) {
  main {margin-bottom: 120px;}
  footer {padding:10px;}
  footer:before {height: 180%; width:20%;}
  footer:after {height: 120%; width:40%;}
}
@media (max-width:325px) {
  footer:before {width: 60%;}
  footer:after {width: 90%;}
}